@import 'src/themes/default/bootstrap';
@import 'src/themes/default/mixins';

body {
	overflow-y: scroll;
	padding-bottom: 10vh;

	p:last-child {
		margin-bottom: 0px;
	}

	fieldset {
		@extend .py-2;
		@extend .my-0;
	}

	.accordion-toggle {
		&[aria-expanded="false"] {
			& > button {
				@include caret(down);
			}
		}

		&[aria-expanded="true"] {
			& > button {
				@include caret(up);
			}
		}
	}

	[data-after] {
		position: relative;

		&:after {
			content: attr(data-after);
			position: absolute;
			top: $spacer;
			right: 0px;
			transform: translate(50%, -50%);
		}
	}

	&:after {
		content: attr(build);
		color: darken($body-bg, 15%);
		position: fixed;
		bottom: 0.5rem;
		right: 0.5rem;
	}
}

// TOAST POPUP STYLES (OVERRIDES TOASTR)
.app-toast {
	border-top-left-radius: 0px !important;
	border-top-right-radius: 0px !important;
}

.c-pointer {
	cursor: pointer;
}

.badge {
	&.text-bg-draft,
	&.text-bg-not-started {
		@extend .text-bg-secondary;
		@extend .text-white;
	}

	&.text-bg-approved {
		@extend .text-bg-success;
		@extend .text-white;
	}

	&.text-bg-submited,
	&.text-bg-in-progress {
		@extend .text-bg-warning;
		@extend .text-white;
	}

	&.text-bg-rejected {
		@extend .text-bg-danger;
		@extend .text-white;
	}

	&.text-bg-unknown {
		@extend .text-bg-dark;
		@extend .text-white;
	}
}


.text-deleted {
	text-decoration: line-through;
	color: $text-muted;
}

.text-right {
	input[type=text] {
		text-align: right;
	}
}

.fas, .fab {
	text-align: center;
	line-height: 100%;
}

.btn {
	@extend .text-nowrap;

	&.btn-outline-default {
		@extend .text-dark;
	}

	& + .btn {
		margin-left: $spacer * 0.5;
	}
}

.form-control, .form-select {
	&[readonly] {
		background-color: $input-disabled-bg;
		border-color: $input-disabled-border-color;
		color: $input-disabled-color;
	}

	&.is-invalid {
		&.ng-pristine {
			border-color: $warning;

			&:focus {
				box-shadow: 0 0 0 $input-btn-focus-width rgba($warning, 0.25);
			}
		}

		&.ng-dirty {
			border-color: $danger;

			&:focus {
				box-shadow: 0 0 0 $input-btn-focus-width rgba($danger, 0.25);
			}
		}
	}
}

.dropdown-menu {
	max-height: 15rem;
	overflow-y: auto;
}

.badge {
	@extend .text-uppercase;
	@extend .text-light;

	&.badge-state {
		@extend .px-2;
		@extend .py-1;
		@extend .ms-1;
		text-align: center;
		display: inline-block;
		width: 1.5em + ($badge-padding-x * 2);
	}
}

.img-thumbnail {
	overflow: hidden;
}

.nav-tabs {
	.nav-item {
		.nav-link {
			font-size: $font-size-lg;
			line-height: $line-height-lg;
		}
	}
}

.table {
	thead {
		tr {
			th {
			}
		}
	}

	tbody {
		tr {
			td {
				vertical-align: middle;
			}

			&:last-child {
				border-bottom-width: $border-width * 2;
				border-bottom-color: $border-color;
				border-bottom-style: $border-style;
			}
		}
	}

	&.table-sm {
		tbody {
			tr {
				td {
					.btn {
						margin-right: $table-cell-padding-x-sm;
						margin-left: $table-cell-padding-x-sm;

						&:first-child {
							margin-left: 0px;
						}

						&:last-child {
							margin-right: 0px;
						}
					}
				}
			}
		}
	}

	&.table-hover {
		tbody {
			tr:hover {
				td {
					background-color: $table-hover-bg !important;
				}
			}
		}
	}

	&.data-table {
		thead {
			tr {
				th {
					border-top-width: $border-width;
					border-top-color: $border-color;
					border-top-style: $border-style;
					vertical-align: middle;

					&.data-table-actions,
					&.data-table-review {
						text-align: center !important;
						text-transform: uppercase;
						width: 1px !important;
					}
				}
			}
		}

		thead, tbody {
			tr {
				.data-table-actions,
				.data-table-review {
					text-align: center !important;
					width: 1px !important;
					white-space: nowrap;
				}

				.data-table-actions {
				}

				.data-table-review {
					background-color: #fffcf6 !important;
				}
			}
		}
	}
}

.divider {
	border-top: $border-width $border-color solid;
	width: 100%;
	margin: $spacer 0px;
}

.bs-datepicker {
	.bs-datepicker-container {
		.bs-calendar-container {
			.bs-media-container {
				.bs-datepicker-head {
					background-color: $primary;
				}

				.bs-datepicker-body {
					td {
						&.week {
							span {
								color: $primary;
							}
						}

						.selected {
							background-color: $primary;
						}
					}
				}
			}
		}
	}
}

// TODO: THESE SHOULD PROB BE INSIDE spinner.component.scss
.spinner-border {
	&.spinner-border-sm {
		vertical-align: middle;
		margin-left: $btn-padding-x;
	}
}

.spinner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: 100%;
	min-height: 50%;
	padding: $grid-gutter-width 0px;
	margin: $grid-gutter-width auto;

	.spinner-border {
		border: none;
		background-image: url('/assets/images/spinner.svg');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		margin-bottom: $spacer;
	}

	.spinner-text {
		@extend .h4;
		text-transform: lowercase;
	}
}

.accounts {
	.sign-in,
	.sign-out,
	.recovery {
		margin-top: 15vh;
	}
}

// TODO: probably should be in the summary.page.scss
.approvals {
	.summary {
		.club-logo {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 1.3rem;
			height: 1.3rem;
			margin-bottom: 0px;

			img {
				max-width: 100%;
				max-height: 100%;
				width: 1.3rem;
				height: auto;
			}
		}

		.table {
			tbody {
				tr {
					td {
						padding: 10px 5px !important;
					}
				}
			}
		}
	}

	.pending {
		.img-thumbnail {
			display: flex;
			justify-content: center;
			align-items: center;
			width: $headings-line-height * $h1-font-size * 2;
			height: $headings-line-height * $h1-font-size * 2;
			margin-bottom: 0px;

			img {
				max-width: 100%;
				max-height: 100%;
			}
		}
	}
}

.step-actions {
	border-top: $border-width $border-color solid;
	padding-top: 10px;
	margin-top: $grid-gutter-width;
}

/**
 * @deprecated
 */
.edit-wizard {
	.step-content {
		@extend .col-22;
		@extend .offset-1;
		min-height: 520px;

		.form-group, .input-group {
			margin-bottom: $spacer;

			& > label {
				text-align: right;
				justify-content: flex-end;
				padding-right: $spacer;
				padding-left: $spacer;

				&:not([class^="col-"]) {
					@extend .col-6;
					@extend .offset-5;
				}
			}

			.form-text {
				@extend .offset-11;
				display: block;
			}

			.form-control {
				&:not([class^="col-"]) {
					@extend .col-8;
				}

				&[type="number"],
				&.text-mask {
					@extend .col-4;
				}
			}

			.custom-select {
				@extend .col-6;
			}

			.custom-file {
				@extend .col-10;

				.custom-file-label {
					text-align: left;
					justify-content: flex-start;
				}
			}
		}
	}
}

.bg-highlight {
	background-color: rgba(200, 201, 202, 0.1);
}



.print-only {
	display: none;
}

.print {
	h2.d-none,
	h3.d-none,
	div.d-none,
	.print-only {
		display: block !important;
	}
}
