// Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

// Custom overrides
@import 'bootstrap';

// Vendors
@import 'node_modules/bootstrap-scss/bootstrap';
@import 'node_modules/angular-archwizard/styles/archwizard.css';
@import 'node_modules/@ng-select/ng-select/themes/default.theme.css';
@import 'node_modules/ngx-bootstrap/datepicker/bs-datepicker.css';
@import 'node_modules/ngx-toastr/toastr-bs5-alert';

// Custom
@import 'main.scss';
@import 'ng-select';
