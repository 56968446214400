/*@import "variables";

@mixin boxify($color: $body-bg, $padding: $spacer) {
	box-shadow: 0px 0px ($padding * 2) 0px rgba(lighten($color, 15%), 0.15) inset;
	background-color: $color;
	border-radius: $border-radius;
	border-width: 1px;
	border-color: lighten($color, 5%);
	border-top-color: lighten($color, 8%);
	border-style: solid;
	padding: $padding;
	margin: 0px;
	overflow: hidden;
}

@mixin shine($color: $body-color) {
	background-image: radial-gradient(ellipse at 50% -50%, rgba($color, 1), rgba($color, 0));
	border-top-color: lighten($color, 8%);
}

@mixin subtle($color: $body-color) {
	$shadow: darken($color, 50%);
	$stroke: lighten($color, 5%);

	text-shadow: 0.00em 0.00em 0.01em rgba($shadow, 0.6), 0.03em 0.02em 0.02em rgba($shadow, 0.4), 0.06em 0.04em 0.03em rgba($shadow, 0.2);
	-webkit-text-stroke: 0.01em $stroke;
	-moz-text-stroke: 0.01em $stroke;
	text-transform: uppercase;
	color: $color;
}

@mixin btn-icon($font-size: $font-size-base, $line-height: $input-btn-line-height, $padding-x: $input-btn-padding-x, $padding-y: $input-btn-padding-y) {
	$width: $font-size * $line-height;



	@extend .btn-block;
	line-height: normal;
	text-align: left;
	position: relative;
	padding-left: $width + ($padding-x * 2) + $padding-x;


	.fas, .fab {
		background-color: rgba($black, 0.1);
		border-right: 1px rgba($black, 0.1) solid;
		box-sizing: content-box;
		text-align: center;
		line-height: normal;
		font-size: $font-size;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0rem;
		right: auto;
		bottom: 0rem;
		left: 0rem;
		width: $width;
		padding: $padding-y $padding-x;
	}
}
*/
