@import 'src/themes/default/bootstrap';

.ng-select {
	&.ng-select-lg {
		.ng-select-container {
			border-color: $primary !important;
			font-size: $font-size-lg;
			line-height: $line-height-lg;
			height: auto !important;
			padding-top: $input-btn-padding-y-lg;
			padding-bottom: $input-btn-padding-y-lg;

			.ng-value-container {
				padding-left: $input-btn-padding-x-lg;

				.ng-input {
					padding-left: 0px !important;

					& > input {
						padding-left: $input-btn-padding-x-lg !important;
					}
				}
			}
		}

		.ng-dropdown-panel {
			border-color: $primary !important;
		}
	}
}
