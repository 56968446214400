/*@import 'theme';

.print {
	h2.d-none,
	h3.d-none,
	div.d-none,
	.print-only {
		display: block !important;
	}
}
*/
